<template>
  <v-container fluid>
    <item-properties />
  </v-container>
</template>

<script >
import { Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "IndustryItem",
  components: {
    ItemProperties: () =>
      import("@/components/reference/industry/ItemProperties.vue"),
  },
});
</script>